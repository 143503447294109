import { styled } from "@mui/material";

export const FlexColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    flexShrink: "1",
    height: "100%",
    width: "100%",
});
