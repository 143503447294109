import { styled } from "@mui/material";

export const FlexRow = styled("div")({
    display: "flex",
    flexDirection: "row",
    flexGrow: "1",
    flexShrink: "1",
    height: "50%",
    width: "100%",
});
